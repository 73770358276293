.b-dark {
  background-color: #2c3333;
}
.b-ligth {
  background-color: #f6f1f1;
}

.navbar {
  box-shadow: rgba(16, 15, 15, 0.3) 0px 25px 20px -20px;
  align-items: center;
}
li {
  display: inline;
  list-style-type: none;
  float: left;
  height: 30px;
  line-height: 30px;
  margin: 2px;
}
li.red {
  color: red;
}
li.yellow {
  color: rgb(173, 230, 38);
}
li.black {
  color: rgb(11, 11, 11);
}
li.light {
  color: rgb(240, 196, 196);
}

/* blog */
/* exploare */
.explore {
  background-color: #000;
  width: 100%;
}

.explore-links {
  height: 30vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* course */

#courses {
  font-size: 0.8rem;
  background-color: #fff;
  padding: 10px;
}

.img-link {
  text-decoration: none;
  color: #002c5b;
}

.img-link:hover {
  opacity: 0.7;
}

.card {
  background-color: #fff;
  width: auto;
}

.card-info .logo {
  width: 2.3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000;
}

.card-info .logo:hover {
  background-color: #000;
}

.links-info {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  margin-top: 2rem;
}

.card-info {
  margin: 2rem 0;
}
.card-info a {
  margin-top: 2rem;
  text-decoration: none;
  color: green;
}
